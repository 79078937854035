//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex'
import StoreDetail from '../components/StoreDetail'
import { checkDealSchedule } from '@/../common/utils/dealSchedule.js'

export default {
  name: 'StoreHomePage',
  components: {
    StoreDetail
  },
  head () {
    return {
      title: this.$getPageTitle('Store' + (this.currentStore ? ' - ' + this.currentStore.name : ''))
    }
  },
  computed: {
    ...mapGetters(['currentStore', 'products', 'storeInfo']),
    productList () {
      if (this.products.deal) {
        return this.products.deal.items.filter(item => checkDealSchedule(item, this.storeInfo ? this.storeInfo.schedule : null))
      }
      return []
    }
  },
  watch: {
    currentStore (newValue) {
      if (!newValue) {
        this.$router.push('/')
      }
    }
  }
}
